"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function Page() {
  const router = useRouter();

  useEffect(() => {
    // Using client-side redirect to make sure Auth0 passed state is not lost
    router.replace("/core");
  }, [router]);

  return;
}
